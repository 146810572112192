@use '@angular/material' as mat;
@use 'table';
@use 'topbar';
@use 'link';
@use 'config';
@use 'sidebar';
@use 'configuration-button';
@use 'selector';
@use 'dashboard';
@use 'v2/rwc-table';
@use 'aux_colors';
@use 'dialog';
@use 'block-panel';
@use 'workspace-status';
@use 'loading';
@use 'metrics';
@use 'date-picker';
@import 'helpers';

@mixin rw-theme($theme) {
  $bg-color: _RWBackground($theme, background);

  .mat-tab-labels {
    padding: 0 0.5em;
  }

  .mat-tab-header {
    border-bottom: none;
  }

  .fixed-header {
    position: relative;
  }

  .fixed-header .mat-tab-header {
    position: sticky !important;
    display: block;
    background-color: $bg-color;
    top: 53px;
    z-index: 1000;
  }

  .mat-checkbox-label {
    display: flex !important;
    align-items: center !important;
    gap: 0.4em;
  }

  .mat-form-field.nohint .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .complexity-options .option {
    color: _RWForeground($theme, text);

    &:disabled {
      color: darken(_RWForeground($theme, text), 30%);
    }

    &:enabled.selected {
      color: _RWPrimary($theme, 200);
    }
  }

  .mat-flat-button.mat-primary {
    color: _RWPrimary($theme, default-contrast);
  }

  span.beta-label {
    background-color: _RWAccent($theme, default);
    color: _RWAccent($theme, default-contrast);
    font-size: 0.8em;
    padding: 0.3em 0.8em;
    border-radius: 8px;
  }

  .mat-form-field.search {
    background: linear-gradient(180deg,
        $bg-color 0%,
        $bg-color 80%,
        rgba(0, 0, 0, 0) 100%);
  }

  realwear-cloud-apk-uploader {
    background-color: darken($bg-color, 5%);

    &[state='error'] {
      background-color: _RWWarn($theme, 100);
      color: _RWWarn($theme, 500);
    }
  }

  .manageProfileContainer {
    background-color: darken($bg-color, 5%);
  }

  @include topbar.rw-topbar($theme);
  @include topbar.rw-topbar($theme);
  @include link.rw-links($theme);
  @include aux_colors.aux_colors($theme);
  @include table.rw-table($theme);
  @include sidebar.rw-sidebar($theme);
  @include selector.choice-selector($theme);
  @include configuration-button.rw-configuration-button($theme);
  @include rwc-table.rwc-table($theme);
  @include dialog.rwc-dialog($theme);
  @include block-panel.rwc-devices($theme);
  @include dashboard.rwc-dashboard($theme);
  @include workspace-status.rwc-workspace-status($theme);
  @include metrics.rwc-metrics($theme);
}
