@use 'sass:map' as map;
@import 'helpers';

@mixin rw-table($theme) {
    $borderRadius: 8px;
    $is-dark: map-get($theme, is-dark);
    .rw-table {
        border: 1px solid _RWForeground($theme, divider);
        border-radius: $borderRadius;
        user-select: none;

        a.row {
            color: unset;
            text-decoration: unset;
        }

        .row {
            display: flex;
            gap: 1em;
            padding: 1em;

            & > * {
                width: 100%;
            }

            &:last-child {
                border-bottom-left-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
            }

            &:not(.header):not(.empty):not(.readonly) {
                &:hover {
                    @if ($is-dark) {
                        background-color: lighten(_RWBackground($theme, background), 5%);
                    }
                    @else {
                        background-color: darken(_RWBackground($theme, background), 5%);
                    }
                    cursor: pointer;
                }

                &:active {
                    @if ($is-dark) {
                        background-color: lighten(_RWBackground($theme, background), 7%);
                    }
                    @else {
                        background-color: darken(_RWBackground($theme, background), 7%);
                    }
                }
            }

            &.header {
                padding-bottom: .5em;
                padding-top: 1.5em;
            }

            &.header, .cell:not(:first-child) {
                @if ($is-dark) {
                    color: rgba($color: _RWForeground($theme, text), $alpha: 0.38);
                }
                @else {
                    color: rgba($color: _RWForeground($theme, text), $alpha: 0.6);
                }
            }

            .cell {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .row:not(:last-child) {
            border-bottom: 1px solid _RWForeground($theme, divider);
        }

        .errHighlight {
            @if ($is-dark) {
                background: _RWColor($theme, admin-bar-dark);
            }
            @else {
                background: _RWColor($theme, admin-bar-light);
            }
        }
    }
}
