.mat-dialog-content.config-dialog.advanced {
    .mat-form-field {
        width: 100%;

        textarea {
            height: 200px;
            font-family: monospace;
            resize: none;
        }
    }
}

.mat-dialog-actions.config-dialog {
    display: grid;
    grid-template-columns: 1fr min-content min-content;
    grid-template-rows: min-content min-content;
    column-gap: 1em;
    row-gap: 0.5em;
    padding-bottom: 0;
    margin-bottom: 0;

    &.no-advanced {
        grid-template-rows: min-content;
        min-height: 38px;
    }

    .advanced {
        color: currentColor;
        opacity: 60%;
        text-align: right;
        grid-area: 2 / 1 / span 1 / span 3;
        text-decoration: none;

        &:hover {
            opacity: 90%;
        }

        &:active {
            color: currentColor;
        }
    }

    .dialogButtonStart {
        justify-self: start;
    }
}
