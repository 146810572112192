@use '@angular/material' as mat;
@use 'sass:map' as map;
@import 'helpers';

@mixin rw-sidebar($theme) {
  $bg-color: _RWBackground($theme, card);
  $selected-bg-color: _RWPrimary($theme, 400);
  $pro-color: _RWColor($theme, pro-badge);
  $bottom-padding: 30px;

  .rwc-sidebar {
    display: grid;
    height: 100%;
    background-color: $bg-color;
    position: relative;
    @include mat.elevation(2);

    .sidebar-host {
      display: grid;
      max-height: 100vh;
      grid-template-rows: 1fr min-content;
    }

    .sidebar-host.fixed {
      --height: 68px;

      position: sticky;
      top: var(--height);
      max-height: calc(100vh - var(--height));
    }

    .dynamic-items {
      overflow-y: auto;

      padding-bottom: $bottom-padding;
    }

    .fixed-items {
      padding-bottom: 1em;

      &>.status {
        margin-left: 20px;
        margin-right: 20px;

        @media screen and (max-height: 500px) {
          margin: 0 10px;
        }
      }

      position: relative;

      &::before {
        display: block;
        position: absolute;
        content: ' ';
        top: -$bottom-padding;
        left: 0;
        right: 0;
        height: $bottom-padding;
        background: linear-gradient(rgba(0, 0, 0, 0), $bg-color);
      }
    }

    .fixed-items,
    .dynamic-items {
      margin: 0 1em;

      .sidebar__item {
        display: flex;
        gap: 10px;
        color: unset;
        text-decoration: unset;
        align-items: center;
        font-size: .9em;
        font-weight: 500;
        margin: .4rem 0;
        padding: 1em 17px;
        border-radius: 8px;
        border: 1px solid transparent;
        cursor: pointer;

        @media screen and (max-height: 500px) {
          padding: .2em .5em;
        }

        &:hover {
          color: unset;
        }

        &:not(.selected):hover {
          @if (map.get($theme, is-dark)) {
            background-color: rgba($color: $selected-bg-color, $alpha: 0.6);
          }

          @else {
            background-color: rgba($color: $selected-bg-color, $alpha: 0.3);
          }
        }

        &.selected:not(.has-sub-menu-selected) {
          background-color: $selected-bg-color;
          color: _RWColor($theme, rw-white);

          &:hover {
            background-color: lighten($color: $selected-bg-color, $amount: 3%);
          }
        }

      }

      .pro_button {
        background-color: _RWColor($theme, pro-badge);

        &:hover {
          background-color: lighten($color: $pro-color, $amount: 3%);
        }

        &:not(.selected):hover {
          @if (map.get($theme, is-dark)) {
            background-color: rgba($color: $pro-color, $alpha: 0.6);
          }

          @else {
            background-color: rgba($color: $pro-color, $alpha: 0.3);
          }
        }
      }
    }
  }

  .rwc-pro-badge {
    border-radius: 5px;
    background-color: _RWColor($theme, pro-badge);
    padding: .2em .5em;
    min-width: 3.5em;
    font-size: 0.9em;
    max-width: 10em;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: uppercase;
    margin-left: auto;
    user-select: none;
    color: lighten(black, 20%);

    @media screen and (max-height: 500px) {
      padding: .1em .4em;
    }
  }
}