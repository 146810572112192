@use '@angular/material' as mat;
@import 'helpers';

@mixin _btn-hover($color) {
  background-color: $color;
  &:hover {
    background-color: lighten($color, 2%);
  }

  &:active {
    background-color: lighten($color, 4%);
  }
}

@mixin rw-configuration-button($theme) {

  $card: _RWBackground($theme, card);

  .app-rw-configuration-button {
    @include _btn-hover(_RWBackground($theme, selected-button));
    &.set {
      border: 1px solid _RWForeground($theme, divider);
    }

    &[disabled='true'] {
      background-color: _RWBackground($theme, disabled-button);

      &:hover,
      &:active {
        background-color: _RWBackground($theme, disabled-button);
      }
    }

    mat-icon.add {
      color: _RWPrimary($theme, 300);
    }
    [badgeIcon]{
      &.warn {
        background-color: _RWColor($theme, pro-accent-yellow);

        mat-icon{
          /* Shades/Black */

          color: _RWBackground($theme, background);
        }
      }
    }
  }
}
