@use '@angular/material' as mat;
@use 'sass:map' as map;
@import 'helpers';

@mixin choice-selector($theme) {
    $selected-bg-color: _RWColor($theme, selected-bg);
    $selected-text-color: _RWColor($theme, selected-text);

    realwear-cloud-choice-selector {
        display: flex;
        gap: .1rem;
        user-select: none;
        cursor: pointer;

        div.option {
            width: 3em;
            text-align: center;
            border-radius: 2px;
            transition-timing-function: ease-in;

            &:not(.selected) {
                opacity: 0.7;

                &:hover {
                    opacity: 0.8;
                    transition-property: background;
                    transition-duration: 100ms;

                    @if (map.get($theme, is-dark)) {
                        background-color: rgba($color: $selected-bg-color, $alpha: 0.1);
                    }
                    @else {
                        background-color: rgba($color: $selected-bg-color, $alpha: 0.1);
                    }
                }
            }

            &.selected {
                transition-property: color, background;
                transition-duration: 200ms;
                background: $selected-bg-color;
                color: $selected-text-color;
            }
        }
    }
}
