@use 'sass:map' as map;
@import 'helpers';

@mixin rwc-metrics($theme) {
  realwear-cloud-metrics-bar {
    .bar {
      &__bar {
        $bg-color: _RWPrimary($theme, 200);
        background-color: rgba($bg-color, 0.9);
        border: 1px solid $bg-color;
        &:hover {
          background-color: $bg-color;
        }
      }
    }
  }
}
