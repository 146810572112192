@use 'sass:map' as map;
@import 'helpers';

@mixin rwc-dialog($theme) {
  $is-dark: map-get($theme, is-dark);

  .mat-dialog-actions.space-between {
    display: flex;
    justify-content: space-between;
  }

  .mat-dialog-title {
    &.with-icon {
      display: flex;
      align-items: center;
      font-size: 2rem;

      .mat-icon {
        font-size: 2rem;
        height: 2rem;
        margin-right: .2rem;
        width: 2rem;
      }

      h1 {
        margin: 0;
      }

      &.with-center {
        justify-content: center;
      }
    }
  }

  .mat-dialog-actions .mat-flat-button.accent {
    background-color: _RWColor($theme, pro-accent-yellow);
    color: _RWContrast($theme, pro-accent-yellow);
  }

  .pending {
    @if ($is-dark) {
      color: _RWColor($theme, pro-accent-yellow);
    }

    @else {
      color: _RWContrast($theme, pro-accent-yellow);
    }
  }

  .mat-icon.info {
    color: _RWColor($theme, pro-accent-yellow);
  }

  ol {
    margin-left: 30px;
  }



  .mat-form-field.stretch-height,
  .mat-form-field.stretch-height .mat-form-field-flex,
  .mat-form-field.stretch-height textarea {
    height: 100%;
  }

  .mat-form-field.stretch-height .mat-form-field-wrapper {
    height: calc(100% - 1.34375em);
  }

  .mat-form-field.stretch-height .mat-form-field-infix {
    height: calc(100% - 1.34375em - 2px);
  }

  .highlighted {
    color: _RWPrimary($theme, 300);
  }

  .features__border {
    border: 3px _RWColor($theme, pro-accent-yellow) solid;
  }
}
