@use '@angular/material' as mat;

@import 'helpers';

@mixin _small-mode() {
    grid-template: 'progress' min-content
        'text' min-content / 1fr;

    .action {
        display: none;
    }
}

@mixin rwc-workspace-status($theme) {
    $is-dark: map-get($theme, is-dark);

    .rwc-workspace-status {
        display: grid;
        cursor: pointer;

        grid-template:
            'text progress' min-content
            'expires expires' min-content
            'action action' min-content
            / min-content 1fr;

        gap: 5px;
        align-items: center;
        font-size: 0.9em;
        user-select: none;

        &.small {
            @include _small-mode();
        }

        @media screen and (max-width: 400px) {
            @include _small-mode();
        }

        .status {
            grid-area: text;
            white-space: nowrap;
            text-transform: uppercase;
            letter-spacing: 1px;

            overflow: hidden;
            text-overflow: ellipsis;
        }

        .progress {
            grid-area: progress;
            $radius: 5px;
            height: 1em;
            position: relative;

            &::before {
                position: absolute;
                content: ' ';

                border-radius: $radius;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                @if ($is-dark) {
                    background-color: _RWColor($theme, progress-dark);
                }

                @else {
                    background-color: _RWColor($theme, progress-light);
                }
            }

            .inner {
                position: absolute;
                content: ' ';
                left: 0px;
                top: 0px;
                bottom: 0px;
                background-color: _RWColor($theme, progress-orange);
                box-sizing: border-box;
                border-radius: $radius;
            }

            &.free {
                .inner {
                    background-color: _RWColor($theme, progress-orange);
                }
            }

            &.green {
                .inner {
                    background-color: _RWColor($theme, main-green);
                }
            }

            &.red {
                .inner {
                    background-color: _RWColor($theme, accent-red);
                }
            }

            &.blue {
                .inner {
                    $lightblue: _RWColor($theme, progress-lightblue);

                    @if ($is-dark) {
                        background-color: $lightblue;
                    }

                    @else {
                        background-color: darken($color: $lightblue, $amount: 10%);
                    }
                }
            }
        }

        .action {
            grid-area: action;

            color: _RWColor($theme, pro-accent-yellow);
            text-transform: uppercase;

            &.pro {
                @if ($is-dark) {
                    color: _RWPrimary($theme, 100);
                }

                @else {
                    color: darken(_RWPrimary($theme, 100), 10%);
                }
            }

            &.channel {
                color: _RWWarn($theme, text);
            }

            &.infinite {
                display: none;
            }

            &.expires {
                grid-area: expires;
                display: block;
            }
        }
    }
}
