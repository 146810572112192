@import 'helpers';

@mixin aux_colors($theme) {
  div.aux_color {
    &.border {
      &.acc-yellow {
        border: 1px solid _RWColor($theme, pro-accent-yellow);
      }
    }
    &.bg {
      background: linear-gradient(180deg, _RWColor($theme, bg-grey1) 0%, _RWColor($theme, bg-grey2) 50%);
      opacity: 0.7;
      color: _RWColor($theme, rw-white);
    }
  }
  mat-icon.aux_color {
    &.green,
    &.online {
        color: _RWColor($theme, main-green);
    }

    &.pending,
    &.wait,
    &.amber {
        color:  _RWColor($theme, amber);
    }

    &.info,
    &.acc-yellow {
      color: _RWColor($theme, pro-accent-yellow);
    }

    &.offline,
    &.stop,
    &.red {
        color: _RWWarn($theme, 500);
    }

    &.missing,
    &.disabled,
    &.greyed_out {
        color: _RWColor($theme, light-grey);
        opacity: .5;
    }
  }
}
