.nav-buttons {
  h2 {
    color: var(--mp-header);
    font-size: 0.9rem;
    font-weight: 300;
    margin: 0 0 .6rem;
    line-height: 1.1;
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    max-width: 800px;
    user-select: none;

    .nav-button {
      background: var(--mp-nav-background-not-selected);;
      border-radius: 4px;
      border: 1px solid transparent;
      cursor: pointer;
      flex-grow: 1;
      font-size: clamp(0.8rem, 0.7458rem + 0.2410vw, 0.9rem);
      font-weight: 400;
      max-width: 160px;
      outline: unset;
      padding: .5rem .8rem;
      text-align: center;
      text-decoration: none;

      color: var(--mp-nav-text-not-selected) !important; // avoid usage of style on 'a' in headset management

      &.selected {
        background-color: var(--mp-nav-background-selected);
        color: var(--mp-nav-text-selected) !important; // avoid usage of style on 'a' in headset management
      }

      &:hover {
        border-color: var(--mp-nav-border-hover);
      }

      &.selected:hover {
        background-color: var(--mp-nav-background-selected-hover);
      }

      &:not(.selected):hover {
        background-color: var(--mp-nav-background-not-selected-hover);
      }

    }
  }

  @media screen and (min-width: 600px) {
    h2 {
      display: none;
      margin-bottom: .8rem;
    }

    nav .nav-button {
      padding: .3rem .8rem;
    }
  }
}

