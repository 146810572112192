@use '@angular/material' as mat;
@use 'sass:map' as map;
@import 'helpers';

@mixin rw-links($theme) {
  a {
    color: _RWPrimary($theme, text);
  }
  .linklike {
    cursor: pointer;
    text-decoration: underline;
    color:  _RWPrimary($theme, text);
  }
}
