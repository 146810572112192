@mixin colors-to-css-variables($map, $prefix) {
  @each $key, $value in $map {
    @if (type-of($value) == 'map') {
      $full-key: "#{$prefix}#{$key}-";
      @include colors-to-css-variables($value, $full-key )
    }
    @if (type-of($value) == 'color') {
      #{$prefix}#{$key}: #{$value};
    }
  }
}
