@use '@angular/material' as mat;
@use "sass:map";
@import 'helpers';

@mixin rwc-dashboard($theme) {
  // $max-width: 1200px;
  $is-dark: map-get($theme, is-dark);
  .rwc-dashboard {
    margin-top: 1em;
    display: grid;
    gap: 2em 1em;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: min-content;
 
    
    .dev-text {
      
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
        }

    .dev-icon {
      display: flex;
      align-items: center;
      padding-top: .3rem;
       
         }
         .icon{
           width:25px;
         }
         
         a {
      text-decoration: none;
    }
    .stat_list {

      &:first-of-type {
        grid-column-start: 1;
      }
      .statContainer {
        .stat {
          align-items: center;
          border-radius: 0.15em;
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 0.2em;
          overflow: hidden;
          padding: 0.5em 0.6em;
          white-space: nowrap;
          color: _RWColor($theme, rw-white);

          .order {
            margin-right: 0.5em;
            flex-shrink: 2;
          }

          .name {
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 0;
          }

          .value {
            text-align: end;
            justify-content: flex-end;
          }
        }

        @if ($is-dark) {
          .stat__1 {
            background-color: _RWPrimary($theme, 300);
          }
          .stat__2 {
            background-color: _RWPrimary($theme, 400);
          }
          .stat__3 {
            background-color: _RWPrimary($theme, 500);
          }
          .stat__4 {
            background-color: _RWPrimary($theme, 600);
          }
          .stat__5 {
            background-color: _RWPrimary($theme, 700);
          }
        }
        @else {
          .stat__1 {
            background-color: _RWPrimary($theme, 200);
          }
          .stat__2 {
            background-color: _RWPrimary($theme, 300);
          }
          .stat__3 {
            background-color: _RWPrimary($theme, 400);
          }
          .stat__4 {
            background-color: _RWPrimary($theme, 500);
          }
          .stat__5 {
            background-color: _RWPrimary($theme, 600);
          }
        }
        .emptyStat {
          background-color: _RWColor($theme, dashboard-stat-blank-light);
          color: _RWColor($theme, rw-black);
          @if ($is-dark) {
            background-color: _RWColor($theme, dashboard-stat-blank-dark);
            color: _RWColor($theme, rw-white);
          }
          border-radius: 0.15em;
        }
      }
      .link {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
      }
    }
    .stat_list, .announcement {
      .link {
        display: flex;
        justify-content: flex-end;
        align-self: flex-end;
        margin-top: 1rem;
      }
    }
    .announcement {
      .media-box {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        background-color: _RWColor($theme, announcement-bg-light);
        @if ($is-dark) {
          background-color: _RWColor($theme, announcement-bg-dark);
        }

        .img-box {
          display: flex;
          background: _RWColor($theme, rw-black);;

          img.media-img {
            width: 275px;
            margin: auto;
          }
        }
      }
    }
  }

  .rwc-reporting {
    margin-top: 1rem;
    display: grid;
    gap: 2em 1em;
    grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));
    grid-auto-rows: min-content;
  }
  .developer {
    display: flex;
        flex-direction: column;

     
      }
      .developer-content{
        border: 1px solid #FFB800;
        padding: 0.5rem;
        
      }
      div {
        font-family: Roboto, brands;
         }

         .brandfont {
          display: flex;
          align-items: center;
          padding-top: .2rem;
         }
}
       
  

