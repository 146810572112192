mat-form-field {
  &.rw-date-range-picker .mat-form-field-infix {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    min-height: 60px;
  }

  &.rw-filter-date-picker .mat-form-field-infix {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}