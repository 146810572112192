@use 'sass:map' as map;
@import '../helpers';

@mixin rwc-table($theme) {
  $is-dark: map-get($theme, is-dark);

  $borderRadius: 8px;
  $divider-color: _RWForeground($theme, divider);


  .rwc-table {
    &__table-container {
      // TODO: this breaks sticky; let's fix later
      //overflow-x: scroll;
    }

    &__controls {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__single {
        padding: 0 0.5rem;
        position: relative;
        border-right: 1px solid $divider-color;

        &:last-child {
          margin-right: -1rem;
          border-right: none;
        }
      }
    }

    &__options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      &__search {
        width: 100%;

        .mat-form-field-wrapper {
          padding: 0;
          margin-bottom: 0;
        }
      }

      &--hasActions {
        flex-wrap: wrap;

        .rwc-table__options__search {
          margin-bottom: 1rem;
        }
      }
    }

    &__table {
      border-spacing: 0;
      border: 1px solid $divider-color;
      border-radius: 0.75rem;

      // TODO: fix later when overflowing thing works
      width: 100%;
      //min-width: 1200px;
    }

    &__cell {
      padding: 0.625rem 1rem;
      height: 56px;
      border-bottom: 1px solid $divider-color;
      line-height: 1.5;

      // strip later
      //pointer-events: none;
      &--checkbox {
        width: 48px;
      }

      &__text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &,
      .mat-sort-header-content {
        text-align: left;
      }

      color: rgba($color: _RWForeground($theme, text), $alpha: 0.6);

      &--header {
        font-weight: 500;
        white-space: nowrap;
        pointer-events: auto;
        position: sticky;
        top: 68px;
        z-index: 99;
        background: _RWBackground($theme, background);
        border-radius: 0.75rem 0.75rem 0 0;
      }

      .mat-sort-header-sorted {
        font-weight: 700;
      }

      &__link {
        text-decoration: none;
        color: inherit;
      }

      .mat-checkbox .mat-checkbox-ripple {
        height: 0;
        width: 0;
      }
    }

    &__row {
      &:last-child {
        .rwc-table__cell {
          border-bottom: none;
        }
      }

      &--hasLink {
        cursor: pointer;

        &:hover {
          @if ($is-dark) {
            background-color: lighten(_RWBackground($theme, background), 5%);
          }

          @else {
            background-color: darken(_RWBackground($theme, background), 5%);
          }
        }
      }
    }

    .paginatorRight {
      padding-right: 5em;
    }

    &__checkbox {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    &__no-item-found {
      margin-bottom: 1.5rem;
    }

    &__paginator {
      background: none !important;
    }

    &.in-dialog {
      .rwc-table__options {
        margin-bottom: 0;
      }
      .rwc-table__cell--header {
        top: 0;
      }
    }
  }
}
