@use '@angular/material' as mat;
@use './components/components' as components;
@import 'base/resets';
@import '../material/scss/main';
@import 'helpers';
@import 'abstracts/mixins/colors-to-css-variables.mixin';

@include mat.core();
@include mat.all-component-themes($realwear-dark-theme);

body {
  &.rw-dark {
    --is-dark: initial;
    --is-light: ;
    @include colors-to-css-variables($realwear-dark-theme, '--');
    @include components.rw-theme($realwear-dark-theme);
  }

  &:not(.rw-dark):not(.mp-public), &.rw-light  {
    --is-dark: ;
    --is-light: initial;
    @include colors-to-css-variables($realwear-light-theme, '--');
    @include components.rw-theme($realwear-light-theme);
    @include mat.all-component-colors($realwear-light-theme);
  }

  &.mp-public {
    @include colors-to-css-variables($realwear-mp-public-theme, '--');
    @include components.rw-theme($realwear-mp-public-theme);

  @include mat.all-component-colors($realwear-mp-public-theme);
  }

  background: var(--background-background);
  color: var(--color-foreground-text);

}
