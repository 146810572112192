@use "@angular/material" as mat;
@use "sass:map";
@import 'helpers';

@mixin rw-topbar($theme) {
  $is-dark: map-get($theme, is-dark);

  $bg-color: _RWBackground($theme, background);
  @if ($is-dark) {
    $bg-color: _RWColor($theme, topbar-bg-dark);
  }

  .topbar {
    background-color: $bg-color;
    display: flex;
    position: sticky;
    top: 0;
    align-items: center;
    gap: .3em;
    z-index: 1000;
    user-select: none;
    color: _RWForeground($theme, text);

    height: 68px;

    @media screen and (max-width: 600px) {
      height: 53px;
    }

    @include mat.elevation(10);

    &.admin {
      background-color: _RWColor($theme, admin-bar-light);
      @if ($is-dark) {
        background-color: _RWColor($theme, admin-bar-dark);
      }
    }

    img.logo {
      height: 34px;
      margin: 6px 12px;

      @if ($is-dark) {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(50deg) brightness(102%) contrast(108%);
      }

      width: 44px;
      overflow: hidden;
      object-fit: cover;
      object-position: left;

      @media screen and (min-width: 600px) {
        width: unset;
      }
    }

    .search {
      margin-left: auto;
    }

    .announcement {
      margin-left: auto;
      margin-right: 5px;
    }

    theme-switcher {
      margin-right: .5em;
    }

    .me {
      display: block;

      .button-content {
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-template-rows: min-content min-content;
        gap: 0 1em;
        align-items: center;
      }

      &__avatar {
        height: 35px;
        width: 35px;
        background-color: _RWColor($theme, avatar);
        color: _RWColor($theme, rw-white);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        grid-row: 1 / -1;

        &.superadmin {
          background-color: _RWWarn($theme, 400);
          border: 2px dashed _RWWarn($theme, 500);
          @if ($is-dark) {
            background-color: _RWWarn($theme, 700);
          }
        }
      }

      &__name, &__ws {
        text-align: left;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
      }

      &__name {
        font-size: larger;
      }

      &__ws {
        font-size: smaller;
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }
}
