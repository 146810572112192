@use '@angular/material' as mat;
@use "sass:map";
@import 'helpers';

@mixin rwc-devices($theme) {
  $is-dark: map-get($theme, is-dark);
  .obscure, .obscure > * {
    filter:blur(4px);
    realwear-cloud-donut-multi-chart {
      .charts, .legend {
        filter:blur(4px);
      }
    }
  }

  .proBlock {
    background: linear-gradient(180deg, _RWColor($theme, bg-grey1) 0%, _RWColor($theme, bg-grey2) 50%);
    border: 1px solid _RWColor($theme, pro-accent-yellow);
    border-radius: 1px;
    box-sizing: border-box;
    opacity: 0.7;
    @include mat.elevation(4);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    left: 0%;
    bottom: 0%;
    right: 0%;
    top: 0%;
    height: auto;
    min-height: auto;
    z-index: 3;
    &.donuts,
    &.bars {
      margin: 0rem 0rem 1.3rem 0rem;
    }
    &.cards {
      opacity: 0.9;
      margin: 2.7rem 0.7rem;
    }

    .checkmark {
      color: _RWColor($theme, rw-white);
      width: 2rem;
      height: 2rem;
      font-size: 2rem;
      margin-bottom: 0.2rem;
    }
    p {
      width: 180px;
      height: 48px;

      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.25px;
      color: _RWColor($theme, pro-text);
      &.proFeature {
        height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: _RWColor($theme, pro-accent-yellow);
      }
    }
  }
  .link.proUpgrade {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    cursor: pointer;

    @if ($is-dark) {
      color: _RWColor($theme, pro-accent-yellow);
    }
    @else {
      color: _RWContrast($theme, pro-accent-yellow);
    }
  }
}
